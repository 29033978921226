<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t('title.enablePlayers') }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="params.search"
          append-icon="mdi-magnify"
          :label="$t('input.search')"
          single-line
          hide-details
          clearable
          @input="fetchListUsers(params)"
          class="mr-5"
      />
    </v-toolbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";
// import DialogDelete from "../../../components/shared/notify/DialogDelete";

export default {
  props: ["darkmode", "params"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    ...mapActions("$_enableDisableAgent", ["fetchListUsers"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
